<template>
    <v-dialog
        v-model="dialog"
        persistent
        max-width="1000px"
        transition="dialog-transition"
        scrollable
    >
        <v-card>
            <v-toolbar color="primary" class="subtitle white--text">
                {{titulo}}
            </v-toolbar>
            <v-card-text class="px-0">
                <v-stepper v-model="paso" vertical class="elevation-0">
                    <v-stepper-step :complete="paso > 0" step="0">
                    REQUISITOS PARA LA SOLICITUD
                    </v-stepper-step>

                    <v-stepper-content step="0" class="py-0 pl-0">
                        <v-card class="elevation-0">
                            <v-card-text class="pa-0">
                                <v-list two-line>
                                    <v-list-item v-for="(requisito,index) in requisitos" :key="index">
                                        <v-list-item-avatar>
                                            <v-icon
                                                class="grey lighten-1"
                                                dark
                                            >{{requisito.icon}}         
                                            </v-icon>
                                        </v-list-item-avatar>
                                        <v-list-item-content>
                                            <v-list-item-title v-html="requisito.titulo"> 
                                            </v-list-item-title>
                                            <v-list-item-subtitle v-html="requisito.subtitulo">
                                            </v-list-item-subtitle>
                                        </v-list-item-content>
                                    </v-list-item>        
                                </v-list>
                            </v-card-text>
                            <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn text color="error" @click="$emit('cancelar')">
                                Cancelar
                                </v-btn>
                                <v-btn
                                    color="primary"
                                    @click="continuar"
                                >
                                    Aceptar y Continuar
                                </v-btn>
                            </v-card-actions>
                        </v-card>
                    </v-stepper-content>

                     <v-stepper-step :complete="paso > 1" step="1">
                        VERIFICACION DE DATOS
                    </v-stepper-step>
                    <v-stepper-content step="1" class="py-0">
                        <v-card class="elevation-0">
                            <v-card-text class="pa-0">
                                <v-form v-model="valid" ref="formularioVerificacion" lazy-validation>
                                    <v-row no-gutters>
                                        <v-col
                                            cols="4"
                                            lg="4"
                                            md="4"
                                            xs="6"
                                        >  
                                            <v-text-field
                                                class="required px-1"
                                                label="Tipo Documento"
                                                readonly
                                                filled
                                                :rules="getRules('required')"
                                                v-model="persona.tipoDocumento"
                                            />
                                        </v-col>
                                        <v-col
                                            cols="4"
                                            lg="4"
                                            md="4"
                                            xs="6"
                                        >  
                                            <v-text-field
                                                class="required px-1"
                                                label="Numero Documento"
                                                readonly
                                                filled
                                                :rules="getRules('required')"
                                                v-model="persona.numeroDocumento"
                                            />
                                        </v-col>
                                        <v-col
                                            cols="4"
                                            lg="4"
                                            md="4"
                                            xs="6"
                                        >  
                                            <v-text-field
                                                class="required px-1"
                                                label="Fecha Nacimiento"
                                                readonly
                                                filled
                                                :rules="getRules('required')"
                                                v-model="persona.fechaNacimiento"
                                            />
                                        </v-col>
                                        <v-col
                                            cols="4"
                                            lg="4"
                                            md="4"
                                            xs="6"
                                        >  
                                            <v-text-field
                                                class="required px-1"
                                                label="Nombres"
                                                readonly
                                                filled
                                                :rules="getRules('required')"
                                                v-model="persona.nombres"
                                            />
                                        </v-col>
                                        <v-col
                                            cols="4"
                                            lg="4"
                                            md="4"
                                            xs="6"
                                        >  
                                            <v-text-field
                                                class="px-1"
                                                label="Primer Apellido"
                                                readonly
                                                filled
                                                :rules="[]"
                                                v-model="persona.primerApellido"

                                            />
                                        </v-col>
                                        <v-col
                                            cols="4"
                                            lg="4"
                                            md="4"
                                            xs="6"
                                        >  
                                            <v-text-field
                                                class="px-1"
                                                label="Segundo Apellido"
                                                readonly
                                                filled
                                                v-model="persona.segundoApellido"
                                            />
                                        </v-col>
                                        <v-col
                                            cols="4"
                                            lg="4"
                                            md="4"
                                            xs="6"
                                        >  
                                            <v-text-field
                                                class="required px-1"
                                                label="Género"
                                                readonly
                                                filled
                                                :rules="getRules('required')"
                                                v-model="persona.genero"
                                            />
                                        </v-col>
                                        <v-col
                                            cols="4"
                                            lg="4"
                                            md="4"
                                            xs="6"
                                        >  
                                            <v-text-field
                                                class="required px-1"
                                                label="Matricula RPA"
                                                readonly
                                                filled
                                                :rules="getRules('required')"
                                                v-model="registro.matricula"
                                            />
                                        </v-col>
                                        <v-col
                                            lg="12"
                                            md="12"
                                            xs="12"
                                        >  
                                            <v-alert type="warning" color="orange darken-4" border="left" dense text :value="true">
                                                En caso de que algún dato este incorrecto, comunicarse con los operadores al número de celular <v-icon color="orange darken-4">mdi-phone</v-icon> <a href="tel:71521522">71521522</a>.
                                            </v-alert>
                                        </v-col>
                                    </v-row>
                                </v-form>
                            </v-card-text>
                            <v-card-actions  class="pt-0">
                                <v-spacer></v-spacer>
                                <v-btn text color="error" @click="consulta=true">
                                    Cancelar
                                </v-btn>
                                    <v-btn
                                    color="primary"
                                    :loading="loading"
                                    @click="continuar"
                                >
                                    SIGUIENTE
                                </v-btn>
                            </v-card-actions>
                        </v-card>
                    </v-stepper-content>

                    <v-stepper-step :complete="paso > 2" step="2">
                        FORMULARIO GENERAL
                    </v-stepper-step>
                    <v-stepper-content step="2" class="py-0">
                        <v-card class="elevation-0">
                            <v-card-text class="pa-0">
                                <v-form v-model="valid" ref="formulario" lazy-validation>
                                    <v-row no-gutters>
                                        <v-col
                                            cols="6"
                                            lg="6"
                                            md="6"
                                            xs="6"
                                        >  <v-select
                                                class="required px-1"
                                                name="departamento"
                                                :items="this.$store.state.session.parametros.departamentos"
                                                v-model="reposicion.departamento"
                                                label="Departamento"
                                                dense
                                                :rules="getRules('requiredSelect')"
                                                filled
                                            ></v-select>
                                        </v-col>
                                        <v-col
                                            cols="6"
                                            lg="6"
                                            md="6"
                                            xs="6"
                                        >
                                            <v-select
                                                class="required px-1"
                                                :items="itemsTipo"
                                                v-model="reposicion.tipo"
                                                label="Tipo Solicitud"
                                                dense
                                                :rules="getRules('requiredSelect')"
                                                filled
                                            ></v-select>
                                        </v-col>
                                        <v-col
                                            lg="6"
                                            md="6"
                                            sm="6"
                                            xs="12"
                                            class="text-center"
                                            cols="12"
                                        >
                                            <!-- <v-file-input
                                                class="required px-1 mt-0"
                                                v-model="reposicion.archivoFotografia"
                                                :rules="getRules('required foto')"
                                                accept="image/jpeg"
                                                prepend-icon="mdi-camera"
                                                label="Fotografia (3x3)"
                                                show-size
                                                @change="onFileChangeFoto"
                                                counter
                                                filled
                                            >
                                                <template v-slot:selection="{ text }">
                                                    <v-chip
                                                        color="primary accent-4"
                                                        dark
                                                        label
                                                        small
                                                    >
                                                        {{ text }}
                                                    </v-chip>
                                                </template>
                                            </v-file-input>
                                            <img v-if="reposicion.urlFotografia" :src="reposicion.urlFotografia" width="180" /> -->
                                            <UploadFoto @change="changeFoto"/>
                                        </v-col>
                                        <v-col
                                            cols="12"
                                            lg="6"
                                            md="6"
                                            sm="6"
                                            xs="12"
                                            class="text-center"
                                        >
                                            <!-- <v-file-input
                                                class="required px-1 mt-0"
                                                v-model="reposicion.archivoFirma"
                                                :rules="getRules('required foto')"
                                                accept="image/jpeg"
                                                prepend-icon="mdi-camera"
                                                label="Firma (3x6)"
                                                show-size
                                                @change="onFileChangeFirma"
                                                counter
                                                filled
                                            >
                                                <template v-slot:selection="{ text }">
                                                    <v-chip
                                                        color="primary accent-4"
                                                        dark
                                                        label
                                                        small
                                                    >
                                                        {{ text }}
                                                    </v-chip>
                                                </template>
                                            </v-file-input>
                                            <img v-if="reposicion.urlFirma" :src="reposicion.urlFirma" width="300px" /> -->
                                            <UploadFoto label="Firma (3x6)" tipo="firma" @change="changeFirma"/>
                                        </v-col>
                                    </v-row>
                                    <datos-contacto />
                                </v-form>
                            </v-card-text>
                            <v-card-actions  class="pt-0">
                                <v-spacer></v-spacer>
                                <v-btn text color="error" @click="consulta=true">
                                    Cancelar
                                </v-btn>
                                    <v-btn
                                    color="primary"
                                    :loading="loading"
                                    @click="validarFormulario()"
                                >
                                    SIGUIENTE
                                </v-btn>
                            </v-card-actions>
                        </v-card>
                    </v-stepper-content>

                    <v-stepper-step :complete="paso > 3" step="3">
                        FIRMA DIGITAL
                    </v-stepper-step>

                    <v-stepper-content step="3" class="py-0">
                        <v-card>
                            <v-card-text class="pa-0">
                                <div>Debes Aprobar Digitalmente tu formulario de solicitud de registro</div>
                                <div id="previewPDF" ></div>
                            </v-card-text>
                            <v-overlay :value="overlay" :absolute="true" color="primary">
                                <v-progress-circular class="pr-5" indeterminate size="50" color="orange"></v-progress-circular>
                                {{mensaje}}
                            </v-overlay>
                            <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn text color="error" @click="consulta=true">
                                        Cancelar
                                    </v-btn>
                                    <v-btn text color="secondary" @click="paso=2">
                                        atras
                                    </v-btn>
                                <v-btn
                                    color="primary"
                                    @click="aprobarDocumento"
                                    :loading="loading"
                                >
                                    APROBAR y GUARDAR SOLICITUD
                                </v-btn>
                            </v-card-actions>
                        </v-card>
                    </v-stepper-content>

                    <!-- <v-stepper-step step="4">
                        CÓDIGO DE PAGO DEL TRAMITE
                    </v-stepper-step>
                    <v-stepper-content step="4" class="py-0">
                        <v-card class="elevation-0" >
                            <v-card-text  class="pa-0">
                                 <codigo-pago :cpt="cpt" :center="true"/>
                            </v-card-text>
                            <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn text @click="$emit('ok')">
                                    Aceptar y Cerrar
                                </v-btn>
                            </v-card-actions>
                        </v-card>
                    </v-stepper-content> -->
                </v-stepper>
                
                <confirmacion
                    :pregunta="consulta"
                    :consulta="pregunta"
                    :loading="loadingConfirmacion"
                    @si="aceptacionConfirmacion"
                    @no="cancelarConfirmacion"
                />
                <!-- <firma-documento 
                    v-if="dialogFirma"
                    :dialog="dialogFirma"
                    :nota="this.nota"
                    titulo="Nota Solicitud Emision Nueva Credencial"
                    @cerrar="dialogFirma=false"
                    @documentoFirmado="registroSolicitudReposicion"
                /> -->
            </v-card-text>
        </v-card>
    </v-dialog>
</template>
<script>
import PDFObject from "@/../paquetes_externos/pdfobject.min";
import mixin_rules from "@/mixins/rules"
import mixinB64toBlob from "@/mixins/b64toblob"
import { mapState } from "vuex";
export default {
    props:{
        dialog:{
            type: Boolean,
            default: false
        }
    },
    components:{
        'datos-contacto': ()=>import("@/components/DatosContacto"),
        'confirmacion': ()=>import("@/common/util/Confirmacion"),
        UploadFoto:()=>import("@/components/UploadFoto")
        // 'firma-documento': ()=>import("@/components/FirmaDocumento"),
        // 'codigo-pago':()=>import("@/components/Cpt")
    },
    mixins:[ mixin_rules, mixinB64toBlob ],
    data(){
        return{
            titulo: "Solicitar Nueva Credencial",
            requisitos:[
                {
                    icon:'mdi-camera',
                    titulo:'Fotografia digital 3x3 fondo <span class="blue--text">BLANCO</span>',
                    subtitulo:'En formato JPEG, no debe ser mayor a 1 MB'
                },
                {
                    icon:'mdi-camera',
                    titulo:'Fotografia de firma identica al Carnet de Identidad de tamaño 3x6',
                    subtitulo:'Opcional solo en caso de actualizacion'
                }
                // {
                //     icon:'mdi-usb-flash-drive  ',
                //     titulo:'Frima Digital',
                //     subtitulo:'Si no cuentas con firma digital presiona en: <a href="https://www.youtube.com/watch?v=EMFF0gXlcBM&list=PLJbHomsPk_rgSkY5k4gObXK79z_LopaS_" target="_blank"> ¿como obtener firma digital?</a>'
                // }
            ],
            paso:0,
            reposicion:{
                departamento: null,
                tipo: null,
                archivoFotografia: null,
                nombreArchivoFotografia:null,
                urlFotografia: null,
                archivoFirma: null,
                nombreArchivoFirma: null,
                urlFirma: null
            },
            itemsTipo:[
                {value: 1,text:'POR VENCIMIENTO O CADUCIDAD'},
                {value: 2,text:'POR DETERIORO'},
                {value: 3,text:'POR PERDIDA'}
            ],
            valid:true,
            nota:null,
            notaFirmada:null,
            loading:false,
            pregunta:'¿Está seguro de cancelar la solicitud?. se eliminaran los datos registrados',
            consulta:false, 
            token:null,
            overlay: false,
            mensaje:'',
            dialogFirma:false,
            dialogAprobacion:false,
            //cpt:{},
            loadingConfirmacion:false
        }
    },
    computed: {
        ...mapState({ 
            registro: state => state.abogado.registro,
            persona: state=>state.abogado.persona
        })
    },
    methods:{
        cancelarRegistro(){
            this.$emit("cancelar")
        },
        // onFileChangeFoto(files){
        //     if(!files){
        //        return this.reposicion.urlFotografia=null
        //     }
        //     const file = files;
        //     this.reposicion.urlFotografia = URL.createObjectURL(file);  
        // },
        // onFileChangeFirma(files){
        //     if(!files){
        //        return this.reposicion.urlFirma=null
        //     }
        //     const file = files;
        //     this.reposicion.urlFirma = URL.createObjectURL(file);   
        // },
        validarFormulario(){
            if(this.$refs.formulario.validate()){
                this.loading = true
                let formData = new FormData();
                formData.append("departamento", this.reposicion.departamento)
                formData.append("tipo", this.reposicion.tipo)
                formData.append("archivoFotografia", this.reposicion.archivoFotografia)
                formData.append("archivoFirma",this.reposicion.archivoFirma)
               
                this.$http.post(`${this.$apiUrl}abogado-ciudadano/credenciales/nota-solicitud`, 
                formData, {
                    headers: {
                        "Content-Type": "multipart/form-data"
                    }
                }).then(response=>{
                    this.nota=response.data.data.pdf
                    setTimeout(() => {
                        this.showDocumento(this.nota);
                    }, 100);
                    this.loading=false,
                    this.paso=3
                }).catch(error=>{
                    console.log('------------------------------------');
                    console.log(error);
                    console.log('------------------------------------');
                    this.loading=false
                })
            }
        },
        firmarDocumento(){
            this.mensaje='Firmando Digitalmente'
            this.overlay=true
            this.dialogFirma= true
        },
        aprobarDocumento(){
            this.mensaje='Aprobando Documento Con Ciudadanía Digital'
            this.overlay=true
            this.dialogAprobacion=true
            this.pregunta='¿Está seguro de aprobar el documento con Ciudadanía Digital?. en caso de que acepte no podra realizar ninguna Modificacíon'
            this.consulta=true
        },
        aceptacionConfirmacion(){
            if(this.dialogAprobacion){
                this.registroSolicitudReposicion()
            }else{
                this.cancelarRegistro()
            }
        },
        cancelarConfirmacion(){
            this.consulta = false
            this.pregunta = '¿Está seguro de cancelar la solicitud?. se eliminaran los datos registrados'
            this.dialogAprobacion = false
            this.overlay=false
        },
        registroSolicitudReposicion(pdf_firmado){
            this.mensaje='Registrando Solicitud Espere un momento ...'
            this.overlay=true
            this.dialogFirma=false
            this.notaFirmada=null
            this.loading = true
            if( this.dialogAprobacion){
                 this.notaFirmada=this.b64toBlob(this.nota)
            }else{
                this.notaFirmada=this.b64toBlob(pdf_firmado)
            }
            let formData = new FormData();
            formData.append("departamento", this.reposicion.departamento)
            formData.append("tipo", this.reposicion.tipo)
            formData.append("archivoFotografia", this.reposicion.archivoFotografia)
            formData.append("archivoFirma",this.reposicion.archivoFirma)
            formData.append("notaSolicitud",this.notaFirmada)
            if(this.dialogAprobacion){
                formData.append("aprobacionCiudadania",true)
                formData.append("rutaCliente",this.$route.fullPath)
            }
            this.loadingConfirmacion = true
            this.$http.post(`${this.$apiUrl}abogado-ciudadano/credenciales`,
                formData, {
                    headers: {
                        "Content-Type": "multipart/form-data"
                    }   
            }).then(response=>{
                this.loading = false
                const data=response.data.data
                this.paso=4
                this.overlay = false
                 this.loadingConfirmacion = false
                if(data.aprobacionCiudadania){
                    if(data.aprobacionCiudadania.linkAprobacion) {
                    this.$notify({
                            group:'foo',
                            type:'success',
                            title:'APROBACION DOCUMENTOS',
                            text:'Debe aprobar los documentos de su solicitud con Ciudadanía Digital'
                        })
                    window.location.href = data.aprobacionCiudadania.linkAprobacion
                    }
                }
                //this.cpt= data.cpt
                // this.$store.commit('addAlerta',{
                //     id: 10,
                //     type:'error',
                //     text: 'Usted tiene un pago pendiente de CPT por motivo de solicitud de REPOSICION DE CREDENCIAL'
                // })
                this.consulta = false  
            }).catch(error=>{
                 this.loadingConfirmacion = false
                this.loading = false
                console.log(error)
            })
        },
        showDocumento(pdf) {
            this.dialog = true;
            const options = {
                height: "350px",
                pdfOpenParams: {
                pagemode: "thumbs",
                navpanes: 0,
                toolbar: 1,
                statusbar: 1,
                view: "FitH"
                }
            };
            PDFObject.embed('data:application/pdf;base64,'+pdf, "#previewPDF", options);
        },
        continuar(){
            if(this.paso === 0){
                this.paso ++
                return
            }
            if(this.paso === 1){
                if(this.$refs.formularioVerificacion.validate()){
                    this.paso ++
                    return
                }
            }
        },
        changeFoto(data){
            this.reposicion.archivoFotografia = data
        },
        changeFirma(data){
            this.reposicion.archivoFirma = data
        }
    }
}
</script>
<style>
.required label::after {
  content: " *";
  color: red;
}
</style>